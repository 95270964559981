import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Table = ({
	element,
	categoryHandler,
	singleCategory,
	categories,
	mainCategoryHandler,
	isProvince,
	dailyHandler,
	isFirst,
	role,
	organizations,
}) => {
	const [buttonMessage, setButtonMessage] = useState(
		"Kategoriyalar bo'yicha saralash"
	);

	useEffect(() => {
		isProvince
			? setButtonMessage("Kategoriyalar bo'yicha saralash")
			: setButtonMessage("Viloyatlar bo'yicha saralash");
	}, [isProvince]);

	const isAdmin = role === 'superadmin' || role === 'province_admin';
	return (
		<div className='row'>
			<div className='col-md-6 col-sm-6 col-lg-12'>
				<div className='mb-3 card '>
					<div className='card-header-tab card-header justify-content-between d-flex'>
						{isAdmin && (
							<>
								<div className='card-header-title'>
									{isProvince
										? 'Viloyatlar kesimida qatnashuvchilar soni'
										: "Yo'nalishlar kesimida qatnashuvchilar soni"}
								</div>
								{role === 'superadmin' && (
									<>
										<button
											className='btn btn-focus'
											onClick={mainCategoryHandler}>
											{buttonMessage}
										</button>

										<Link
											className='btn btn-info'
											to='/download'>
											Yuklanmalar
										</Link>

										<button
											className='btn btn-primary'
											onClick={dailyHandler}>
											{isFirst ? 'Kunlik' : 'umumiy'}
										</button>
									</>
								)}

								<div className='btn-actions'>
									<div className='nav'>
										<div className='btn-actions-pane-right'>
											<div className='nav'>
												<select
													onChange={(e) =>
														categoryHandler(e)
													}
													// value={singleCategory}

													className='border-0 btn-pill btn-wide btn-transition active btn btn-outline-alternate'>
													<option disabled selected>
														Yo'nalishlar
													</option>
													<option
														value={JSON.stringify({
															name: 'all',
														})}>
														Barchasi
													</option>

													{organizations.map(
														(category, i) => (
															<option
																key={i}
																value={JSON.stringify(
																	category
																)}>
																{category.name}
															</option>
														)
													)}
												</select>
											</div>
										</div>
									</div>
								</div>

								<div className='btn-actions'>
									<div className='nav'>
										<div className='btn-actions-pane-right'>
											<div className='nav'>
												<select
													onChange={(e) =>
														categoryHandler(e)
													}
													className='border-0 btn-pill btn-wide btn-transition active btn btn-outline-alternate'>
													<option
														value=''
														disabled
														selected>
														Ichki yo'nalishlar
													</option>

													{categories.map(
														(category, i) => (
															<option
																key={i}
																value={JSON.stringify(
																	category
																)}>
																{category.name}
															</option>
														)
													)}
												</select>
											</div>
										</div>
									</div>
								</div>
							</>
						)}
					</div>
					<div className='tab-content'>
						<div
							className='tab-pane fade active show'
							id='tab-eg-55'>
							<div className='widget-chart p-3'>
								<div>
									<div className='row'>
										<div className='col-md-12'>
											<div className='main-card mb-3 '>
												<div className='table-responsive'>
													<table className='align-middle mb-0 table table-borderless table-striped table-hover'>
														<thead>
															<tr>
																<th>
																	{isProvince
																		? 'Viloyatlar kesimida qatnashuvchilar soni'
																		: "Yo'nalishlar"}
																</th>
																<th className='text-center'>
																	Ro'yxatdan
																	o'tganlar
																	soni
																</th>
															</tr>
														</thead>
														<tbody>
															{element?.map(
																(item) => {
																	return item;
																}
															)}
														</tbody>
													</table>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Table;
