import React, { useEffect, useState } from 'react';
import logo from '../assets/logo.png';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Constants from '../constants';
import { Loader } from '../components/Loader';

import editing from '../assets/editing.png';
import trash from '../assets/trash.png';
import { useSelector } from 'react-redux';
import axios from 'axios';
const Region = () => {
	const [loading, setLoading] = useState(true);
	const [loading2, setLoading2] = useState(false);
	const [isModal, setisModal] = useState(false);
	const [formData, setFormData] = useState({});
	const [response2, setResponse2] = useState([]);

	const userStore = useSelector((prev) => prev.user);

	const [data, setData] = useState([]);
	const [type, setType] = useState('');
	const [name, setName] = useState('');

	const { id } = useParams();
	const navigate = useNavigate('');

	const [loading3, setLoading3] = useState(false);

	const [leaderInputs, setLeaderInputs] = useState({
		seria: '',
		seria_number: '',
		birthdate: '',
		phone: '',
	});

	const [leaderData, setLeaderData] = useState({});

	const [message, setMessage] = useState('');
	const [successLeader, setsuccessLeader] = useState(false);

	const [organizations, setOrganizations] = useState([]);
	const [categories, setCategories] = useState([]);

	const getData = async () => {
		if (!userStore.role && userStore.region) {
			navigate('/');
		}
		setLoading(true);
		const response = await fetch(
			Constants.BASE_URL +
				'district-statistics-by-region?status=All&region=' +
				id,
			{
				headers: {
					Authorization: 'Bearer ' + userStore.token,
				},
			}
		);

		const organizationApi = await axios.get(
			Constants.BASE_URL + 'categories?status=All'
		);

		setOrganizations(organizationApi.data);
		const response2 = await fetch(
			Constants.BASE_URL + 'district/?region=' + id,
			{
				headers: {
					Authorization: 'Bearer ' + userStore.token,
				},
			}
		);

		const data = await response.json();
		const data2 = await response2.json();
		setResponse2(data2);

		const districsts = data.district_statistics;

		const districtArray = Object.entries(districsts).map(
			([name, count], i) => {
				const data = {
					name,
					count,
				};

				const districtData = data2.find((item) => item.name === name);

				if (districtData) {
					data.portal_id = districtData.portal_id;
					data.soato = districtData.soato;
					data.leader_name = districtData.leader_name;
				}

				return data;
			}
		);

		setData(districtArray);

		if (response.status == 404 || response.status == 500) {
			throw new Error('');
		}
		setLoading(false);
	};
	useEffect(() => {
		// setLoading(true);
		try {
			getData();
		} catch (error) {
			alert(error);
			setLoading(false);
		}
	}, []);

	const deleteHandler = (name) => {
		setName(name);
		setType('delete');
		setisModal(true);
	};
	const editHandler = (name) => {
		setName(name);
		setType('edit');
		setisModal(true);

		try {
			setLoading2(true);
			const getData = async () => {
				const response = await fetch(
					Constants.BASE_URL + `district/?name=${name}&region=${id}`,
					{
						headers: {
							Authorization: 'Bearer ' + userStore.token,
						},
					}
				);
				if (response.status == 404 || response.status == 500) {
					throw new Error('');
				}
				const data = await response.json();

				setFormData(data);

				setLoading2(false);
			};
			getData();
		} catch (error) {
			alert(error);
			setLoading(false);
		}
	};

	const deleteDistricHandler = () => {
		setLoading(true);
		try {
			const deleteData = async () => {
				const response = await fetch(
					Constants.BASE_URL + `district/?name=${name}&region=${id}`,
					{
						method: 'delete',

						headers: {
							Authorization: 'Bearer ' + userStore.token,
						},
					}
				);
				if (response.status == 404 || response.status == 500) {
					throw new Error('');
				}

				if (response.status == 400) {
					setMessage(
						"Bu mahalladan 100 nafardan ortiq yoshlar ro'yxatdan o'tgan. Siz bu mahallani o'chirolmaysiz"
					);

					setTimeout(() => {
						setMessage('');
					}, 4000);
					return;
				}
				setLoading(false);
				setisModal(false);
				getData();
			};
			deleteData();
		} catch (error) {
			alert(error);
			setLoading(false);
		}
	};

	const editDistrictHandler = (evt) => {
		evt.preventDefault();
		try {
			const editData = async () => {
				const response = await fetch(
					Constants.BASE_URL + `district/?name=${name}&region=${id}`,
					{
						method: 'put',
						body: JSON.stringify({
							...formData,
							leader_passport_seria:
								leaderInputs.seria + leaderInputs.seria_number,
							leader_pinfl: leaderData.pinpp,
							leader_birthdate: leaderData.birth_date,
							leader_phone_number: leaderInputs.phone,
							leader_gender:
								leaderData.gender === 'male' ? 'M' : 'F',
							leader_name:
								leaderData.first_name +
								' ' +
								leaderData.last_name +
								' ' +
								leaderData.patronymic,
							sector: formData.sector,
						}),
						headers: {
							'Content-type': 'Application/json',
							Authorization: 'Bearer ' + userStore.token,
						},
					}
				);
				if (response.status == 404 || response.status == 500) {
					throw new Error('');
				}

				setLeaderInputs({
					seria: '',
					seria_number: '',
					birthdate: '',
					phone: '',
				});
				setLeaderData({});
				setLoading(false);
				setisModal(false);
				getData();
				setsuccessLeader(false);
			};
			editData();
		} catch (error) {
			alert(error);
			// setLoading(false);
		}
	};

	const getDocumentHandler = async () => {
		setLoading3(true);

		if (
			leaderInputs.seria &&
			leaderInputs.seria_number &&
			leaderInputs.birthdate &&
			leaderInputs.phone
		) {
			const formdata = new FormData();
			formdata.append(
				'document',
				leaderInputs.seria + leaderInputs.seria_number
			);
			formdata.append('birthdate', leaderInputs.birthdate);
			const res = await axios.post(
				Constants.BASE_URL + 'passport-seria/',
				formdata
			);

			if (res.status === 200) {
				const data = JSON.parse(res.data.data);

				if (data.status === 'error') {
					alert("Ma'lumotlar topilmadi");
					setsuccessLeader(false);
					setLoading3(false);
					return;
				}

				setsuccessLeader(true);
				setLoading3(false);
				setLeaderData(data.user_data);
			} else {
				alert("Ma'lumotlar topilmadi");
				setsuccessLeader(false);
				setLoading3(false);
			}
		} else {
			alert("Ma'lumotlar topilmadi");
			setsuccessLeader(false);
			setLoading3(false);
		}
	};

	const categoryHandler = async (evt) => {
		setLoading(true);
		const item = JSON.parse(evt.target.value);

		if (item.name === 'all') {
			setCategories([]);
		} else {
			const innerApi = await axios.get(
				Constants.BASE_URL + `categories?status=All&parent=${item.id}`
			);
			if (innerApi.data.length > 0) {
				setCategories(innerApi.data);
			}
		}

		let url;
		if (item.name == 'all') {
			url =
				Constants.BASE_URL +
				`district-statistics-by-region?region=${id}&status=All`;
		} else {
			url =
				Constants.BASE_URL +
				`district-statistics-by-region?region=${id}&status=All&category=${item.name}`;
		}

		try {
			const apiGets = async () => {
				const province = await axios.get(url);

				const districsts = province.data.district_statistics;

				const districtArray = Object.entries(districsts).map(
					([name, count], i) => {
						const data = {
							name,
							count,
						};

						const districtData = response2.find(
							(item) => item.name === name
						);

						if (districtData) {
							data.portal_id = districtData.portal_id;
							data.soato = districtData.soato;
							data.leader_name = districtData.leader_name;
						}

						return data;
					}
				);

				setData(districtArray);

				setLoading(false);
			};

			apiGets();
		} catch (error) {
			setLoading(false);
		}
	};

	return (
		<div className='app-container app-theme-white body-tabs-shadow w-100'>
			<div className='app-header header-shadow'>
				<div className='p-3 ml-3 row d-flex justify-content-around align-items-center'>
					<div className='logo-sr'>
						{userStore.role === 'superadmin' ? (
							<Link to='/'>
								<img width={'60%'} src={logo} alt='logo' />
							</Link>
						) : (
							<img width={'60%'} src={logo} alt='logo' />
						)}
					</div>

					<div className='btn-actions btn-focus mx-5'>
						<div className='nav'>
							<div className='btn-focus'>
								<div className='nav'>
									<select
										onChange={(e) => categoryHandler(e)}
										className=' btn-info border-0 btn-pill btn-transition active btn '>
										<option disabled selected>
											Yo'nalishlar
										</option>
										<option
											value={JSON.stringify({
												name: 'all',
											})}>
											Barchasi
										</option>

										{organizations.map((category, i) => (
											<option
												key={i}
												value={JSON.stringify(
													category
												)}>
												{category.name}
											</option>
										))}
									</select>
								</div>
							</div>
						</div>
					</div>
					<div className='btn-actions'>
						<div className='nav'>
							<div className='btn-actions-pane-right'>
								<div className='nav'>
									<select
										onChange={(e) => categoryHandler(e)}
										className='border-0 btn-pill btn-wide btn-transition active btn btn-outline-alternate'>
										<option value='' disabled selected>
											Ichki yo'nalishlar
										</option>

										{categories.map((category, i) => (
											<option
												key={i}
												value={JSON.stringify(
													category
												)}>
												{category.name}
											</option>
										))}
									</select>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='app-main '>
				<div className='app-main__outer' style={{ paddingLeft: '0' }}>
					<div className='app-main__inner w-100 m-auto'>
						<div>
							{loading ? (
								<Loader />
							) : (
								<table className='table table-striped table-bordered'>
									<thead>
										<tr>
											<th>#</th>
											<th>Mahalla nomi</th>
											<th>Ro'yxatdan o'tganlar soni</th>
											<th>Mahalla soatosi</th>
											<th>Mahalla id raqami</th>
											<th>Mahalla yetakchisi</th>

											{userStore.role === 'superadmin' ||
											userStore.region === id ? (
												<>
													<th>
														Mahalla ma'lumotlarini
														o'zgartirish
													</th>
													<th>Mahallani o'chirish</th>
												</>
											) : (
												''
											)}
										</tr>
									</thead>
									<tbody>
										{data.map((item, i) => (
											<tr key={i}>
												<td>{i + 1}</td>
												<td>{item.name}</td>
												<td>{item.count}</td>
												<td>
													{item.soato
														? item.soato
														: 'Mavjud emas'}
												</td>
												<td>
													{item.portal_id
														? item.portal_id
														: 'Mavjud emas'}
												</td>
												<td>
													{item.leader_name
														? item.leader_name
														: 'Mavjud emas'}
												</td>

												{userStore.role ===
													'superadmin' ||
												userStore.region === id ||
												userStore.role ===
													'province_admin' ? (
													<>
														<td>
															<img
																style={{
																	cursor: 'pointer',
																}}
																src={editing}
																width={20}
																onClick={() =>
																	editHandler(
																		item.name
																	)
																}
																alt='edit icon'
															/>
														</td>
														<td>
															<img
																style={{
																	cursor: 'pointer',
																}}
																onClick={() =>
																	deleteHandler(
																		item.name
																	)
																}
																src={trash}
																width={20}
																alt='delete icon'
															/>
														</td>
													</>
												) : (
													''
												)}
											</tr>
										))}
									</tbody>
								</table>
							)}
						</div>
					</div>
				</div>
			</div>

			<>
				<div
					id='myModal'
					className='modal h-100  position-fixed  flex justify-content-center '
					style={isModal ? { display: 'flex' } : { display: 'none' }}>
					<div className='modal-content overflow-auto  mt-lg-2  w-75 d-flex '>
						<div className='modal-header'>
							<h3>{name}</h3>
							<span
								className='close'
								onClick={() => setisModal(false)}>
								&times;
							</span>
						</div>
						<div className='modal-body'>
							{loading2 ? (
								<div className='d-flex justify-content-center align-items-center'>
									<div
										className='spinner-border'
										role='status'>
										<span className='sr-only'>
											Loading...
										</span>
									</div>
								</div>
							) : type === 'edit' ? (
								<form
									onSubmit={editDistrictHandler}
									className='form-control h-auto h-auto p-3'>
									<h4 className='m-0'>
										Yetakchi ma'lumotlari :
										<span className='ml-3'>
											{successLeader
												? leaderData.first_name +
												  ' ' +
												  leaderData.last_name
												: ''}

											{!successLeader &&
											formData.leader_name
												? formData.leader_name
												: ''}
										</span>
									</h4>

									<label className='w-100 p-1 ' htmlFor=''>
										Yetakchi pasport seriyasi:
										<input
											className='form-control h-auto p-1 pl-2 mt-1'
											placeholder='AB'
											type='text'
											required
											maxLength='2'
											name='seria'
											value={leaderInputs.seria}
											onChange={(e) => {
												if (
													leaderInputs.seria.length <
													3
												) {
													setLeaderInputs({
														...leaderInputs,
														seria: e.target.value.toUpperCase(),
													});
												}
											}}
										/>
									</label>
									<label
										className='w-100 p-1 mt-1'
										htmlFor=''>
										Yetakchi pasport raqami:
										<input
											className='form-control h-auto p-1 pl-2 mt-1'
											placeholder='_______'
											type='text'
											required
											maxLength='7'
											name='pasport-number'
											value={leaderInputs.seria_number}
											onChange={(e) => {
												if (
													leaderInputs.seria.length <=
													7
												) {
													setLeaderInputs({
														...leaderInputs,
														seria_number:
															e.target.value.toUpperCase(),
													});
												}
											}}
										/>
									</label>
									<label htmlFor=''>
										Tug'ilgan sanani kiriting
									</label>
									<input
										name='birth-year'
										className='form-control h-auto p-1 pl-2 mt-1'
										value={leaderInputs.birthdate}
										required
										onChange={(evt) => {
											setLeaderInputs({
												...leaderInputs,
												birthdate: evt.target.value,
											});
										}}
										type='date'
									/>
									<label className='mt-2' htmlFor=''>
										Telefon raqamini kiriting (998911231212
										formatida)
										{formData.leader_phone_number
											? '   (Raqami) ' +
											  formData.leader_phone_number
											: ''}
									</label>

									<input
										required
										name='birth-year'
										className='form-control h-auto p-1 pl-2 mt-1'
										value={leaderInputs.phone}
										onChange={(evt) => {
											setLeaderInputs({
												...leaderInputs,
												phone: evt.target.value,
											});
										}}
										type='phone'
										placeholder='998xxxxxxxxx'
									/>

									<div className='d-flex justify-content-end mr-5 mt-2'>
										{loading3 ? (
											<button
												className='btn btn-success'
												type='button'>
												Iltimos kuting...
											</button>
										) : (
											<button
												className='btn btn-success'
												type='button'
												onClick={getDocumentHandler}>
												Yuborish
											</button>
										)}
									</div>
									<label
										htmlFor=''
										className='w-100 p-1 mt-1'>
										Mahallaning nomi:
										<input
											className='form-control h-auto p-1 pl-2 mt-1'
											placeholder='Mahalla nomi'
											type='text'
											name=''
											value={formData.name}
											onChange={(evt) => {
												setFormData({
													...formData,
													name: evt.target.value,
												});
											}}
										/>
									</label>
									<label className='w-100 p-1 mt-2'>
										Mahallaning soatosi:
										<input
											className='form-control h-auto p-1 pl-2 mt-1'
											placeholder='Mahallaning soatosi'
											type='text'
											name=''
											required
											value={
												formData.soato == 0
													? ''
													: formData.soato
											}
											onChange={(evt) => {
												setFormData({
													...formData,
													soato: evt.target.value,
												});
											}}
										/>
									</label>
									<label
										className='w-100 p-1 mt-2'
										htmlFor=''>
										Mahallaning id raqami:
										<input
											className='form-control h-auto p-1 pl-2 mt-1'
											placeholder='Mahallaning id raqami'
											type='text'
											required
											name=''
											value={
												formData.portal_id == 0
													? ''
													: formData.portal_id
											}
											onChange={(evt) => {
												setFormData({
													...formData,
													portal_id: evt.target.value,
												});
											}}
										/>
									</label>

									<label
										className='w-100 p-1 mt-2'
										htmlFor='sector'>
										Sectorni tanlang
										<select
											className='w-100 p-1 mt-2'
											name='sector'
											id='sector'
											value={
												formData.sector
													? formData.sector
													: ''
											}
											onChange={(evt) => {
												setFormData({
													...formData,
													sector: evt.target.value,
												});
											}}>
											<option disabled></option>
											<option value='1-sektor'>
												1-sektor
											</option>
											<option value='2-sektor'>
												2-sektor
											</option>
											<option value='3-sektor'>
												3-sektor
											</option>
											<option value='4-sektor'>
												4-sektor
											</option>
										</select>
									</label>

									{successLeader ? (
										<div className='d-flex justify-content-end  mt-2 mr-5'>
											<button
												className='btn btn-info  mr-5'
												onClick={() => {
													setisModal(false);
												}}>
												Ortga qaytish
											</button>
											{loading2 ? (
												<button
													className=' btn btn-success'
													type='button'>
													Iltimos biroz kuting...
												</button>
											) : (
												<button
													className=' btn btn-success'
													type='submit'>
													Mahalla ma'lumotlarini
													o'zgaritirish
												</button>
											)}
										</div>
									) : (
										''
									)}
								</form>
							) : (
								<>
									{message ? (
										<h4 className='text-center text-danger my-5'>
											{message}
										</h4>
									) : (
										''
									)}
									<div className='d-flex justify-content-around mt-3'>
										<button
											className='btn btn-info'
											onClick={() => {
												setisModal(false);
											}}>
											Ortga qaytish
										</button>
										<button
											className='btn btn-danger'
											onClick={deleteDistricHandler}>
											Mahallani o'chirish
										</button>
									</div>
								</>
							)}
						</div>
					</div>
				</div>
			</>
		</div>
	);
};

export default Region;
