import React from 'react';

const Layout = ({
	children,
	logo,
	allMemberNumber,
	dailyNum,
	allActiveMemberNumber,
}) => {
	return (
		<div className='app-container app-theme-white body-tabs-shadow w-100'>
			<div className='app-header header-shadow'>
				<div className='app-header__logo'>
					<div className='logo-sr'>
						<img width={'60%'} src={logo} alt='logo' />
					</div>
				</div>
			</div>

			<div className='app-main '>
				<div className='app-main__outer' style={{ paddingLeft: '0' }}>
					<div className='app-main__inner'>
						<div className='row mb-3'>
							<div className=' col-6 col-xl-4'>
								<div className='card widget-content bg-midnight-bloom'>
									<div className='widget-content-wrapper text-white'>
										<div className='widget-content-left'>
											<div className='widget-heading'>
												Umumiy ro'yxatdan o'tganlar
											</div>
											<div className='widget-subheading'>
												Respublika miqyosida
											</div>
										</div>
										<div className='widget-content-right'>
											<div className='widget-numbers text-white'>
												<span className='h3'>
													{allMemberNumber}
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='col-6 col-xl-4'>
								<div className='card widget-content bg-grow-early'>
									<div className='widget-content-wrapper text-white'>
										<div className='widget-content-left'>
											<div className='widget-heading'>
												Faol yo'nalishlar bo'yicha
											</div>
											<div className='widget-subheading'>
												Respublika miqyosida
											</div>
										</div>
										<div className='widget-content-right'>
											<div className='widget-numbers text-white'>
												<span className='h3'>
													{allActiveMemberNumber}
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className='col-6 col-xl-4'>
								<div className='card widget-content bg-arielle-smile'>
									<div className='widget-content-wrapper text-white'>
										<div className='widget-content-left'>
											<div className='widget-heading'>
												Bugungi ro'yxatdan o'tganlar
											</div>
											<div className='widget-subheading'>
												Respublika miqyosida
											</div>
										</div>
										<div className='widget-content-right'>
											<div className='widget-numbers text-white'>
												<span className='h3'>
													{dailyNum}{' '}
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						{children}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Layout;
