import React, { useEffect, useState } from 'react';
import logo from '../assets/logo.png';
import { Link } from 'react-router-dom';
import * as Constants from '../constants';
import { Loader } from '../components/Loader';
const DownloadPage = () => {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);

	useEffect(() => {
		setLoading(true);
		try {
			const getData = async () => {
				const response = await fetch(Constants.BASE_URL + 'downloads/');
				const data = await response.json();
				setData(data);
				setLoading(false);
			};
			getData();
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	}, []);

	if (loading) {
		return <Loader />;
	}
	return (
		<div className='app-container app-theme-white body-tabs-shadow w-100'>
			<div className='app-header header-shadow'>
				<div className='p-3 row justify-content-around'>
					<div className='logo-sr'>
						<Link to='/'>
							<img width={'60%'} src={logo} alt='logo' />
						</Link>
					</div>
					<div>
						<h3 className=''>
							Iltimos biroz kutib sahifani yangilang, yangi
							ma'lumotlarni yuklash uchun
						</h3>
					</div>
				</div>
			</div>

			<div className='app-main '>
				<div className='app-main__outer' style={{ paddingLeft: '0' }}>
					<div className='app-main__inner w-50 m-auto'>
						<div>
							{data.map((item) => (
								<div
									key={item.id}
									className='	btn bg-primary text-white border border-primary  p-2 m-2 d-block'>
									<a
										className='text-white text-decoration-none'
										target='_blank'
										rel='noopener noreferrer'
										href={
											'https://adm.beshtashabbus.uz' +
											item.file
										}>
										{item.name}
									</a>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DownloadPage;
