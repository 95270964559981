function CustomModal({
	isModal,
	spanHandler,
	loading2,
	regionList,
	provinceDownloader,
	role,
}) {
	return (
		<>
			<div
				id='myModal'
				className='modal  h-auto position-absolute  mt-lg-5 flex justify-content-center '
				style={isModal ? { display: 'flex' } : { display: 'none' }}>
				<div className='modal-content overflow-auto  mt-lg-5  w-75 d-flex '>
					<div className='modal-header'>
						<span className='close' onClick={spanHandler}>
							&times;
						</span>
					</div>
					<div className='modal-body'>
						<div className='table-responsive'>
							{loading2 && (
								<div className='d-flex justify-content-center align-items-center'>
									<div
										className='spinner-border'
										role='status'>
										<span className='sr-only'>
											Loading...
										</span>
									</div>
								</div>
							)}

							<table className='align-middle mb-0 table table-borderless table-striped table-hover'>
								{!loading2 && (
									<thead>
										{role === 'superadmin' && (
											<tr>
												<th colSpan={5}>
													<button
														onClick={
															provinceDownloader
														}
														className='btn btn-primary'>
														Viloyat ma'lumotlarini
														yuklab olish
													</button>
												</th>
											</tr>
										)}
										<tr>
											<th className='text-center'>#</th>
											<th>Tuman</th>
											<th className='text-center'>
												Ro'yxatdan o'tganlar
											</th>
											<th className='text-center'>
												Ma'lumotlarni yuklab olish
											</th>
										</tr>
									</thead>
								)}

								<tbody>
									{!loading2 &&
										regionList.map((item) => {
											return item;
										})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default CustomModal;
