import React, { useRef, useState } from 'react';
import logo from '../assets/logo.png';
import axios from 'axios';
import * as Constants from '../constants';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../store/slicers/userSlicer';
import { useNavigate } from 'react-router-dom';
const Login = () => {
	const name = useRef('');
	const password = useRef('');
	const [error, setError] = useState('');

	const userStore = useSelector((prev) => prev.user);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const loginHandler = (e) => {
		e.preventDefault();
		if (name !== '' && password !== '') {
			axios
				.post('https://adm.beshtashabbus.uz/authenticate/', {
					username: name.current.value,
					password: password.current.value,
				})
				.then((res) => {
					const data = res.data.data;
					sessionStorage.setItem('token', data.token.access);
					sessionStorage.setItem('role', data.user.role);
					sessionStorage.setItem('region', data.user.region);
					sessionStorage.setItem(
						'first_name',
						data.user.role === 'superadmin'
							? 'superadmin'
							: data.user.first_name
					);
					sessionStorage.setItem('province', data.user.province);

					dispatch(
						login({
							role: data.user.role,
							token: data.token.access,
							region: data.user.region,
							province: data.user.province,
							first_name: data.user.first_name,
						})
					);

					// console.log(data.user);
					if (data.user.role === 'region_admin') {
						navigate('/region/' + data.user.region);
					}

					// if (data.user.role === 'province_admin') {
					// 	navigate('/province/' + data.user.province);
					// }
					if (
						data.user.role === 'superadmin' ||
						data.user.role === 'province_admin'
					) {
						navigate('/');
					}
				})
				.catch((err) => {
					setError("Login yoki parol noto'g'ri");
					console.log(err);
				});
		}
	};

	return (
		<div className='container'>
			<div className='row'>
				<div className='col-md-6 offset-md-3'>
					<div className='card my-5'>
						<form
							onSubmit={loginHandler}
							className='card-body p-lg-5'
							style={{ backgroundColor: '#ebf2fa' }}>
							<div className='text-center'>
								<img
									className='my-2'
									src={logo}
									alt='besh tashabbus logo'
									width={186}
									heaight={100}
								/>
							</div>

							<div className='mb-2'>
								<input
									ref={name}
									type='text'
									className='form-control'
									id='Username'
									aria-describedby='emailHelp'
									placeholder='User Name'
									required
								/>
							</div>
							<div className='mb-3'>
								<input
									ref={password}
									type='password'
									className='form-control'
									id='password'
									placeholder='password'
									required
								/>
							</div>
							<div className='text-center'>
								<button
									style={{
										backgroundColor: '#0e1c36',
										color: '#fff',
									}}
									type='submit'
									className='btn btn-color  px-5 mb-5 w-100'>
									Login
								</button>
								{error ? (
									<p className='text-danger font-size-lg'>
										{error}
									</p>
								) : null}
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Login;
