import { createSlice } from '@reduxjs/toolkit';

const userSlicer = createSlice({
	name: 'user',
	initialState: {
		role: sessionStorage.getItem('role'),
		isLogin: false,
		token: sessionStorage.getItem('token'),
		region: sessionStorage.getItem('region'),
		first_name: sessionStorage.getItem('first_name'),
		province: sessionStorage.getItem('province'),
	},
	reducers: {
		login: (state, action) => {
			return {
				role: action.payload.role,
				isLogin: true,
				token: action.payload.token,
				region: action.payload.region,
				first_name: action.payload.first_name,
				province: action.payload.province,
			};
		},
		logout: (state, action) => {
			return {
				role: '',
				isLogin: false,
				token: '',
				region: '',
				first_name: '',
				province: '',
			};
		},
	},
});
export const { login, logout } = userSlicer.actions;

export const userReducers = userSlicer.reducer;
