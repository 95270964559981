import axios from 'axios';

import { useEffect, useState } from 'react';
import CustomModal from '../components/Modal';
import Table from '../components/Table';
import { Link, useNavigate } from 'react-router-dom';
import * as Constants from '../constants';
import logo from '../assets/logo.png';
import { Loader } from '../components/Loader';
import { useDispatch, useSelector } from 'react-redux';

import Layout from '../components/Layout';
import { login } from '../store/slicers/userSlicer';
function Home() {
	const { BASE_URL } = Constants;

	const navigate = useNavigate();

	const [allMemberNumber, setAllMemberNumber] = useState([]);
	const [allActiveMemberNumber, setAllActiveMemberNumber] = useState([]);
	const [dailyNum, setDailyNum] = useState([]);
	const [province, setProvince] = useState([]);
	const [data, setData] = useState([]);
	const [categories, setCategories] = useState([]);
	const [organizations, setOrganizations] = useState([]);
	const [loading, setLoading] = useState(false);
	const [loading2, setLoading2] = useState(false);
	const [isModal, setIsModal] = useState(false);
	const [isProvince, setisProvince] = useState(true);

	const [provinceData, setProvinceData] = useState('');

	const [regions, setRegions] = useState([]);
	const [singleCategory, setSingleCategory] = useState('');
	const [isFirst, setIsFirst] = useState(true);
	const [isDaily, setIsDaily] = useState(false);
	const [province2, setProvince2] = useState([]);

	const userStore = useSelector((prev) => prev.user);

	const dispatch = useDispatch();
	const spanHandler = (e) => {
		setIsModal(false);
	};

	useEffect(() => {
		setLoading(true);

		const role = sessionStorage.getItem('role');
		const token = sessionStorage.getItem('token');

		if (role && token) {
			dispatch(
				login({
					role,
					token: sessionStorage.getItem('token'),
					region: sessionStorage.getItem('region'),
					first_name: sessionStorage.getItem('first_name'),
					province: sessionStorage.getItem('province'),
					isLogin: true,
				})
			);
		}

		try {
			const apiGets = async () => {
				const daily = await axios.get(BASE_URL + 'daily-participants');
				const dailyActive = await axios.get(
					BASE_URL + 'get-all-participants'
				);
				const dailyAll = await axios.get(
					BASE_URL + 'get-all-participants?status=All'
				);
				const organizationApi = await axios.get(
					BASE_URL + 'categories?status=All'
				);

				setOrganizations(organizationApi.data);
				const province = await axios.get(
					userStore.province
						? BASE_URL +
								'province-participants-count?category=All&province=' +
								userStore.province
						: BASE_URL + 'province-participants-count?category=All'
				);

				setDailyNum(daily.data.participants);
				setAllActiveMemberNumber(dailyActive.data.participants);
				setAllMemberNumber(dailyAll.data.participants);

				let sortable = [];
				let data = province.data;
				// let num = 0;
				sortable = data.map((item) => {
					// num += item.participants_count
					// 	? item.participants_count
					// 	: 0;
					return {
						name: item.name,
						value: item.participants_count,
					};
				});

				// setAllMemberNumber(num);

				setProvince(
					sortable.sort((a, b) => {
						return b.value - a.value;
					})
				);
				setData(
					sortable.sort((a, b) => {
						return b.value - a.value;
					})
				);
				setLoading(false);
			};

			apiGets();
		} catch (error) {
			setLoading(false);
		}
	}, []);

	const mainCategoryHandler = () => {
		setLoading2(true);
		try {
			const apiGets = async () => {
				if (!isProvince) {
					setProvince(data);
					setisProvince(!isProvince);
				} else {
					const data2 = await axios.get(
						BASE_URL + 'categories/count'
					);
					const data = data2.data.categories;
					let sort = [];
					sort = data.map((item) => {
						return {
							name: item.name,
							value: item.participants_count,
						};
					});
					setProvince(
						sort.sort((a, b) => {
							return b.value - a.value;
						})
					);
					setisProvince(!isProvince);
				}

				setIsFirst(true);
				setLoading2(false);
			};
			apiGets();
		} catch (error) {
			setLoading2(false);
		}
	};
	const categoryHandler = async (evt) => {
		setLoading2(true);
		const item = JSON.parse(evt.target.value);

		let url;
		if (item.name !== '') {
			url = item.name;
		} else {
			url = 'All';
		}
		const api =
			item.name === 'all'
				? BASE_URL +
				  'province-statistics-by-category?category=' +
				  url +
				  '&status=all'
				: BASE_URL + 'province-statistics-by-category?category=' + url;
		const innerApi = await axios.get(
			BASE_URL + `categories?status=All&parent=${item.id}`
		);
		if (item.name !== 'all') {
			setSingleCategory(item.name);
		}
		if (innerApi.data.length > 0) {
			setCategories(innerApi.data);
		}

		try {
			const apiGets = async () => {
				const province = await axios.get(
					api
					// BASE_URL + 'province-statistics-by-category?category=' + url
				);

				let sortable = [];

				for (let prov in province.data.province_statistics) {
					sortable.push([
						prov,
						province.data.province_statistics[prov],
					]);
				}
				sortable.sort(function (a, b) {
					return a[1] - b[1];
				});
				let objSorted = {};
				sortable.reverse().forEach(function (item) {
					objSorted[item[0]] = item[1];
				});

				setProvince2(objSorted);
				setIsFirst(false);
				setLoading2(false);
			};

			apiGets();
		} catch (error) {
			setLoading2(false);
		}
	};

	const dailyHandler = () => {
		try {
			const apiGets = async () => {
				if (isDaily) {
					setProvince(data);

					setIsFirst(true);
				} else {
					setLoading2(true);

					const data = await axios.get(
						BASE_URL +
							'province-statistics-by-category?category=All&period=day'
					);
					let sortable = [];

					for (let prov in data.data.province_statistics) {
						sortable.push([
							prov,
							data.data.province_statistics[prov],
						]);
					}
					sortable.sort(function (a, b) {
						return a[1] - b[1];
					});
					let objSorted = {};
					sortable.reverse().forEach(function (item) {
						objSorted[item[0]] = item[1];
					});
					setIsFirst(false);
					setProvince2(objSorted);
					setLoading2(false);
				}
			};
			apiGets();
			setIsDaily(!isDaily);
		} catch (error) {
			setLoading2(false);
		}
	};

	let element = [];
	let elem2 = [];

	for (const item in province2) {
		if (userStore.role === 'province_admin') {
			if (item === userStore.province) {
				elem2.push(
					<tr key={item}>
						<td>
							<div className='widget-content p-0'>
								<div className='widget-content-wrapper'>
									<div className='widget-content-left flex2'>
										<div className='widget-heading'>
											{item}
										</div>
									</div>
								</div>
							</div>
						</td>
						<td className='text-center'>{province2[item]}</td>
						<td className='text-center'>
							<button
								type='button'
								id='PopoverCustomT-4'
								className='btn btn-primary btn-sm'
								onClick={(i) => regionHandler(item)}>
								Batafsil
							</button>
						</td>
					</tr>
				);
			}
		} else {
			elem2.push(
				<tr key={item}>
					<td>
						<div className='widget-content p-0'>
							<div className='widget-content-wrapper'>
								<div className='widget-content-left flex2'>
									<div className='widget-heading'>{item}</div>
								</div>
							</div>
						</div>
					</td>
					<td className='text-center'>{province2[item]}</td>
					<td className='text-center'>
						<button
							type='button'
							id='PopoverCustomT-4'
							className='btn btn-primary btn-sm'
							onClick={(i) => regionHandler(item)}>
							Batafsil
						</button>
					</td>
				</tr>
			);
		}
	}
	element.push(
		province.map((item, i) => {
			if (userStore.role === 'province_admin') {
				if (item.name === userStore.province) {
					return (
						<tr key={i}>
							<td>
								<div className='widget-content p-0'>
									<div className='widget-content-wrapper'>
										<div className='widget-content-left flex2'>
											<div className='widget-heading'>
												{item.name}
											</div>
										</div>
									</div>
								</div>
							</td>
							<td className='text-center'>{item.value}</td>
							{isProvince && (
								<td className='text-center'>
									<button
										type='button'
										id='PopoverCustomT-4'
										className='btn btn-primary btn-sm'
										onClick={(i) => regionHandler(item)}>
										Batafsil
									</button>
								</td>
							)}
						</tr>
					);
				}
			} else {
				return (
					<tr key={i}>
						<td>
							<div className='widget-content p-0'>
								<div className='widget-content-wrapper'>
									<div className='widget-content-left flex2'>
										<div className='widget-heading'>
											{item.name}
										</div>
									</div>
								</div>
							</div>
						</td>
						<td className='text-center'>{item.value}</td>
						{isProvince && (
							<td className='text-center'>
								<button
									type='button'
									id='PopoverCustomT-4'
									className='btn btn-primary btn-sm'
									onClick={(i) => regionHandler(item)}>
									Batafsil
								</button>
							</td>
						)}
					</tr>
				);
			}
		})
	);

	const regionHandler = (item) => {
		let id = '';
		setLoading2(true);
		setProvinceData(item);
		setIsModal(true);
		if (item.name === 'Qoraqalpog‘iston Respublikasi') {
			id = 1;
		} else if (
			item.name === 'Andijon viloyati' ||
			item === 'Andijon viloyati'
		) {
			id = 2;
		} else if (
			item.name === 'Buxoro viloyati' ||
			item === 'Buxoro viloyati'
		) {
			id = 3;
		} else if (
			item.name === 'Jizzax viloyati' ||
			item === 'Jizzax viloyati'
		) {
			id = 4;
		} else if (
			item.name === 'Qashqadaryo viloyati' ||
			item === 'Qashqadaryo viloyati'
		) {
			id = 5;
		} else if (
			item.name === 'Navoiy viloyati' ||
			item === 'Navoiy viloyati'
		) {
			id = 6;
		} else if (
			item.name === 'Namangan viloyati' ||
			item === 'Namangan viloyati'
		) {
			id = 7;
		} else if (
			item.name === 'Samarqand viloyati' ||
			item === 'Samarqand viloyati'
		) {
			id = 8;
		} else if (
			item.name === 'Surxandaryo viloyati' ||
			item === 'Surxandaryo viloyati'
		) {
			id = 9;
		} else if (
			item.name === 'Sirdaryo viloyati' ||
			item === 'Sirdaryo viloyati'
		) {
			id = 10;
		} else if (
			item.name === 'Toshkent viloyati' ||
			item === 'Toshkent viloyati'
		) {
			id = 11;
		} else if (
			item.name === 'Farg‘ona viloyati' ||
			item === 'Farg‘ona viloyati'
		) {
			id = 12;
		} else if (
			item.name === 'Xorazm viloyati' ||
			item === 'Xorazm viloyati'
		) {
			id = 13;
		} else if (
			item.name === 'Toshkent shahri' ||
			item === 'Toshkent shahri'
		) {
			id = 14;
		}
		try {
			const url =
				singleCategory !== ''
					? BASE_URL +
					  `region-statistics-by-province?province=${id}&category=${singleCategory}`
					: BASE_URL + 'region-statistics-by-province?province=' + id;
			const regionGet = async () => {
				const regionsApi = await axios.get(url);

				let sortable = [];
				for (let reg in regionsApi.data.region_statistics) {
					sortable.push([
						reg,
						regionsApi.data.region_statistics[reg],
					]);
				}
				sortable.sort(function (a, b) {
					return a[1] - b[1];
				});
				let objSorted = {};
				sortable.reverse().forEach(function (item) {
					objSorted[item[0]] = item[1];
				});
				setRegions(objSorted);
				setLoading2(false);
			};
			regionGet();
		} catch (error) {
			setLoading2(false);
		}
	};

	let regionList = [];

	for (let item in regions) {
		regionList.push(
			<tr>
				<td className='text-center text-muted'></td>
				<td>
					<div className='widget-content p-0'>
						<div className='widget-content-wrapper'>
							<div className='widget-content-left flex2'>
								<div className='widget-heading'>{item}</div>
								<div className='widget-subheading opacity-7'></div>
							</div>
						</div>
					</div>
				</td>
				<td className='text-center'> {regions[item]}</td>
				<td className='text-center'>
					{userStore.role === 'superadmin' ? (
						<button
							onClick={(e) =>
								singleProvincePeriodHandler(e, item)
							}
							className='border-0 btn-primary btn-wide btn-transition active btn btn-outline-primary'>
							Tuman ma'lumotlarini yuklash
						</button>
					) : (
						''
					)}

					<Link
						className='border-0 btn-primary btn-wide btn-transition active btn btn-outline-primary ms-5 ml-5'
						to={'/region/' + item}>
						Batafsil
					</Link>
				</td>
			</tr>
		);
	}
	if (loading) {
		return <Loader />;
	}

	// all data for excel
	const excelHandler = () => {
		const url = !singleCategory
			? BASE_URL + `participantsExcel/`
			: BASE_URL + `participantsExcel/?category=${singleCategory}`;
		const regionGet = async () => {
			const regionsApi = await axios.get(
				!isDaily ? url : url + '&daily=true'
			);

			if (regionsApi.status === 200) {
				navigate('/download');
				setLoading2(false);
			}
		};
		regionGet();
	};

	// region data for excel
	const singleProvincePeriodHandler = (e, item) => {
		try {
			const url = !singleCategory
				? BASE_URL + `participantsExcel/?region=${item}`
				: BASE_URL +
				  `participantsExcel/?region=${item}&category=${singleCategory}`;
			const singleProvince = async () => {
				const singleProvinceApi = await axios.get(
					!isDaily ? url : url + '&daily=true'
				);

				if (singleProvinceApi.status === 200) {
					navigate('/download');
					setLoading2(false);
				}
			};

			singleProvince();
		} catch (error) {
			alert("serverda xato yuz berdi iltimos qayta urinib ko'ring");
		}
	};
	// province data for excel
	const provinceDownloader = () => {
		try {
			const url = !singleCategory
				? BASE_URL + `participantsExcel/?province=${provinceData.name}`
				: BASE_URL +
				  `participantsExcel/?province=${provinceData.name}&category=${singleCategory}`;
			const provinceDownload = async () => {
				const provinceDownloadApi = await axios.get(
					!isDaily ? url : url + '&daily=true'
				);

				if (provinceDownloadApi.status === 200) {
					navigate('/download');
					setLoading2(false);
				}
			};

			provinceDownload();
		} catch (error) {
			alert("serverda xato yuz berdi iltimos qayta urinib ko'ring");
		}
	};
	return (
		<Layout
			allActiveMemberNumber={allActiveMemberNumber}
			logo={logo}
			allMemberNumber={allMemberNumber}
			dailyNum={dailyNum}>
			{loading2 && (
				<div className='d-flex h-100 justify-content-center align-items-center'>
					<div className='spinner-border' role='status'>
						<span className='sr-only'>Loading...</span>
					</div>
				</div>
			)}
			{!loading2 && (
				<>
					<CustomModal
						role={userStore.role}
						isModal={isModal}
						spanHandler={spanHandler}
						regionList={regionList}
						loading2={loading2}
						provinceData={provinceData}
						provinceDownloader={provinceDownloader}
					/>

					<Table
						organizations={organizations}
						role={userStore.role}
						isProvince={isProvince}
						isFirst={isFirst}
						element={isFirst ? element : elem2}
						categoryHandler={categoryHandler}
						singleCategory={singleCategory}
						categories={categories}
						mainCategoryHandler={mainCategoryHandler}
						dailyHandler={dailyHandler}
						excelHandler={excelHandler}
					/>
				</>
			)}
		</Layout>
	);
}

export default Home;
