import React, { useEffect, useState } from 'react';
import Home from './pages/Home.jsx';
import DownloadPage from './pages/DownloadPage.jsx';
import Login from './pages/Login.jsx';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Region from './pages/Region.jsx';
import { login } from './store/slicers/userSlicer.js';
const App = () => {
	const userStore = useSelector((prev) => prev.user);

	const dispatch = useDispatch();
	useEffect(() => {
		const token = sessionStorage.getItem('token');
		const role = sessionStorage.getItem('role');
		const region = sessionStorage.getItem('region');
		const first_name = sessionStorage.getItem('first_name');
		if (token && role) {
			dispatch(
				login({
					role,
					token,
					region,
					first_name,
				})
			);
		}
	}, []);

	return (
		<>
			<BrowserRouter>
				<Routes>
					{userStore.role === 'superadmin' ? (
						<Route
							path='/download'
							element={<DownloadPage />}></Route>
					) : (
						''
					)}
					{!userStore.isLogin ? (
						<Route path='/' element={<Login />} />
					) : (
						<>
							<Route path='/region/:id' element={<Region />} />
							{userStore.role === 'superadmin' ||
							userStore.role === 'province_admin' ? (
								<Route path='*' element={<Home />} />
							) : (
								''
							)}
						</>
					)}
				</Routes>
			</BrowserRouter>
		</>
	);
};

export default App;
